
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.feature-list-with-image-preview {
  display: flex;
  justify-content: space-between;
  @include handleAllBreakpoints("align-items", "verticalAlign", "center");
  @include handleAllBreakpoints("gap", "gap", "3.5rem");

  @include handleAllBreakpoints("flex-direction", "imagePlacement", "column::row");

  div.imageContainer {
    position: relative;
    @include handleAllBreakpoints("width", "imageWidth", "100%");
    @include handleAllBreakpoints("height", "imageHeight", "100%");
    @include handleAllBreakpoints("min-width", "imageMinWidth", "unset");
    @include handleAllBreakpoints("min-height", "imageMinHeight", "unset");
    @include handleAllBreakpoints("margin", "imageMargin", "unset");
    img {
      width: 100%;
      height: 100%;
    }
  }

  .feature-list {
    position: relative;
    @include handleAllBreakpoints("max-width", "listMaxWidth", "100%");
    @include handleAllBreakpoints('margin', 'featureListMargin', 'unset');

    .title {
      line-height: normal;
      margin-bottom: 1.5rem;
      @include handleAllBreakpoints("font-size", "titleSize", "var(--global-sections-typography-title-size)");
      @include handleAllBreakpoints("color", "titleColor", "var(--global-sections-typography-title-color)");
      @include handleAllBreakpoints("font-weight", "titleWeight", "var(--global-sections-typography-title-weight)");
      @include handleAllBreakpoints("text-align", "titleAlign", "left");
      @include handleAllBreakpoints("margin", "titleMargin", "0 0 1.5rem 0");
    }

    .description {
      line-height: normal;
      @include handleAllBreakpoints(
        "font-size",
        "descriptionSize",
        "var(--global-sections-typography-description-size)"
      );
      @include handleAllBreakpoints("color", "descriptionColor", "var(--global-sections-typography-description-color)");
      @include handleAllBreakpoints(
        "font-weight",
        "descriptionWeight",
        "var(--global-sections-typography-description-weight)"
      );
      @include handleAllBreakpoints("text-align", "descriptionAlign", "left");
      @include handleAllBreakpoints("margin", "descriptionMargin", "0 0 4.5rem 0");
    }

    .items-container {
      display: flex;
      flex-direction: column;
      @include handleAllBreakpoints("gap", "featureListGap", "1.5rem");

      margin: 0 0 2.5rem 0;
    }

    .item {
      display: flex;
      align-items: center;
      @include handleAllBreakpoints("gap", "itemGap", "1rem");

      @include handleAllBreakpoints("flex-direction", "itemDirection", "column::row");

      @include handleAllBreakpoints("text-align", "itemAlign", "left");

      @include handleAllBreakpoints("background", "itemBackgroundColor");
      @include handleAllBreakpoints("box-shadow", "itemShadow");
      @include handleAllBreakpoints("border-radius", "itemBorderRadius");
      @include handleAllBreakpoints("border", "itemBorder");
      @include handleAllBreakpoints("padding", "itemPadding");

      h3 {
        line-height: normal;
        @include handleAllBreakpoints("font-size", "itemTitleSize");
        @include handleAllBreakpoints("color", "itemTitleColor", "var(--global-sections-typography-title-color)");
        @include handleAllBreakpoints("font-weight", "itemTitleWeight");
      }

      p {
        line-height: normal;
        @include handleAllBreakpoints("font-size", "itemDescriptionSize");
        @include handleAllBreakpoints("font-weight", "itemDescriptionWeight");

        @include handleAllBreakpoints(
          "color",
          "itemDescriptionColor",
          "var(--global-sections-typography-description-color)"
        );
      }

      .image {
        @include handleAllBreakpoints("width", "itemImageWidth", "50px");
        @include handleAllBreakpoints("height", "itemImageHeight", "50px");
        img,
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          @include handleAllBreakpoints("width", "itemImageWidth", "50px");
          @include handleAllBreakpoints("height", "itemImageHeight", "50px");
        }
        .counter {
          @include handleAllBreakpoints(
            "background-color",
            "counterBackgroundColor",
            "var(--global-sections-buttons-background-color, #000)"
          );

          @include handleAllBreakpoints("color", "counterTextColor", "var(--global-sections-buttons-text-color, #fff)");

          @include handleAllBreakpoints(
            "font-size",
            "counterTextSize",
            "var(--global-sections-typography-description-size, 1.5rem)"
          );
          @include handleAllBreakpoints(
            "border-radius",
            "counterBorderRadius",
            "var(--global-sections-buttons-border-radius, 4px)"
          );

          @include handleAllBreakpoints("border", "counterBorder", "var(--global-sections-buttons-border, none)");
        }
      }
    }
  }

  // btn style
  .scrollToTopPreview {
    display: block;
    border-radius: 0.5rem;
    // font-weight: 600;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    @include handleAllBreakpoints("color", "buttonTextColor", "var(--global-sections-buttons-text-color, white)");
    @include handleAllBreakpoints("font-size", "buttonTextSize", "18px");
    @include handleAllBreakpoints("font-weight", "buttonTextWeight", "600");
    @include handleAllBreakpoints(
      "background",
      "buttonBackgroundColor",
      "var(--global-sections-buttons-background-color, black)"
    );
    @include handleAllBreakpoints(
      "border-radius",
      "buttonBorderRadius",
      "var(--global-sections-buttons-border-radius, 4px)"
    );
    @include handleAllBreakpoints("border", "buttonBorder", "none");
    @include handleAllBreakpoints("box-shadow", "buttonBoxShadow", "none");
    @include handleAllBreakpoints("min-width", "buttonMinWidth", "unset");
    @include handleAllBreakpoints("padding", "buttonPadding", "1rem 4rem");
    @include handleAllBreakpoints("margin", "buttonMargin", "1rem auto");

    &:hover {
      @include handleAllBreakpoints("color", "hoverTextColor", "var(--global-sections-buttons-text-color, white)");
      @include handleAllBreakpoints(
        "background",
        "hoverButtonBackgroundColor",
        "var(--global-sections-buttons-background-color, black)"
      );
      @include handleAllBreakpoints("border", "hoverButtonBorder", "var(--global-sections-buttons-border, none)");
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 300ms;
      transform: translateY(-0.5rem);
    }
  }
}
